<template>
    <div class="container main-container mt-5">
        <div class="row justify-content-center">
            <img src="../../assets/logo.png" alt="ApontaFácil logo" class="img-fluid col-8">
        </div>
        <form @submit.prevent="doLogin">
            <div class="row my-4 text-center justify-content-center">
                <h5 class="col-12">Informe o código do dashboard</h5>
                <div class="col-6">
                    <input 
                        type="text" 
                        class="form-control text-center" 
                        step="1"
                        autofocus 
                        v-model="code"
                        @keypress="clearTimeout"
                        :disabled="loading">
                </div>
            </div>
            <div class="row justify-content-center">
                <button class="btn btn-primary col-5 col-md-4" :disabled="loading">
                    <template v-if="!loading">Acessar</template>
                    <template v-else><i class="fa fa-spinner fa-spin"></i> Carregando...</template>
                </button>
            </div>
        </form>
        <div class="mt-5 text-center" v-if="autoLogin.enabled">
            <p>Acesso automático usando o código acima em 10 segundos...</p>
            <div class="progress">
                <div 
                    class="progress-bar progress-bar-striped progress-bar-animated" 
                    :style="{width: autoLogin.width+'%'}"
                    role="progressbar" 
                    aria-valuenow="10" 
                    aria-valuemin="0" 
                    aria-valuemax="10"></div>
            </div>
        </div>
        <!-- </div> -->
    </div>
</template>

<script>
import Login from '../../classes/Login'
import Swal from 'sweetalert2'

export default {
    name:'LoginFormBootstrap',
    data() {
        return {
            code: '',
            loading: false,
            autoLogin: {
                enabled: true,
                timer: null,
                width: 100
            }
        }
    },

    watch: {
        code() {
            if (this.autoLogin.enabled) {
                this.autoLogin.enabled = false
                window.clearTimeout(this.autoLogin.timer)
            }
        }
    },
    methods: {
        async doLogin() {
            window.clearTimeout(this.autoLogin.timer)
            this.autoLogin.enabled = false
            this.loading = true
            try {
                const login = new Login(this.code)
                if (Number.parseInt(login.code.value, 2) == (new Date().getFullYear() - 2000)) 
                    this.$router.push('/simul')
                else {
                    try {
                        if (await login.doLogin())
                            this.$router.push(`/${login.code.value}`)
                        else
                            Swal.fire('Não é possível conectar a este dashboard', 'Código desconhecido', 'error')
                    }
                    catch (e) {
                        Swal.fire(
                            !e.error ? 'Não foi possível acessar o dashboard' : 'Erro do sistema', 
                            e.msg || e, 
                            'error'
                        )
                    }
                }
            }
            catch (e) {
                Swal.fire('Código inválido', e, 'error')
            }
            this.loading = false
        }
    },
    created() {
        const setAutoLoginTimer = () => {
            this.autoLogin.timer = window.setTimeout(() => {
                if (this.autoLogin.width > 0) {
                    this.autoLogin.width -= 1
                    setAutoLoginTimer()
                }
                else {
                    this.doLogin()
                }
            }, 100)
        }

        this.code = window.localStorage.getItem('code') || ''
        this.autoLogin.enabled = this.code != ''
        if (this.autoLogin.enabled)
            setAutoLoginTimer()
    },
    unmounted() {
        window.clearTimeout(this.autoLogin.timer)
    }
}
</script>

<style scoped>
.main-container {max-width: 500px}
</style>