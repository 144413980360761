const DashboardCode = require('./DashboardCode')
const config = require('../config')

class Login {
    constructor (code, updateLocalStorage = true) {
        this.code = new DashboardCode(code)
        this._updateLocalStorage = updateLocalStorage
        this.token = ''
    }

    _save(token, code) {
        if (!this._updateLocalStorage)
            return

        window.localStorage.setItem('token', token)
        window.localStorage.setItem('code', code)
    }

    async doLogin() {
        const loginUrl = config.createLink(`/login/${this.code.value}`)
        const response = await fetch(loginUrl, {method: 'GET'})
        if (response.status == 200) {
            this.token = await response.text()
            this._save(this.token, this.code.value)
        }
        else 
            this._save('', '')
        return response.status == 200
    }
}

module.exports = Login