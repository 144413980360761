class DashboardCode {
    constructor(code) {
        code = typeof code == 'string' ? code.trim() : ''
        if (!code) {
            throw `Código vazio: ${code}`
        }
        if (code.length != 5){
            throw `O código deve ser composto de 5 caracteres. Código: ${code}`
        }
        console.log(code)
        this.value = code.toLowerCase()
    }
}

module.exports = DashboardCode